import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';

// Styling 
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function SampleByNominationForm(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;

    const defaultRow = {
        gomRequestId: 0,
        blendDate: "",
        Tank: "",
        Blend: "",
        Plant: "",
        Customer: "",
        Formula: "",
        Language: "",
        CmcsCode: "",
        NominationKey: "",
        NominationItem: "",
        SequenceId: "",
        GomType: 1,
        ResponseToGom: "",
        RequestedBy: ""
    };

    const { setAlert } = useAlert();
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [NominationValue, SelectedNominationValue] = React.useState(SelectedValue);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;
    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };
    const handleTextFieldChange = (event) => {
        NominationValue[event.target.id] = event.target.value;
        SelectedNominationValue(NominationValue);
    };


    const saveSampleByNominationValues = async () => {

        const serviceUrl = _apiUrl + "GomRequestSampleByNomination"
        if (NominationValue.plant == null || NominationValue.plant === undefined || NominationValue.plant === "") {
            setAlert("primary", "Validation", "Please enter Plant");
            return false;
        }
        if (NominationValue.formula == null || NominationValue.formula === undefined || NominationValue.formula === "") {
            setAlert("primary", "Validation", "Please enter Formula");
            return false;
        }
        NominationValue.requestedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(NominationValue))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                //throw new Error;
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    return (

        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="plant"
                                label="Plant"
                                defaultValue={NominationValue.plant}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>

                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="formula"
                                label="Formula"
                                defaultValue={NominationValue.formula}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="customer"
                                label="Customer"
                                defaultValue={NominationValue.customer}
                                onChange={handleTextFieldChange}

                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="nominationKey"
                                label="NominationKey"
                                defaultValue={NominationValue.nominationKey}
                                onChange={handleTextFieldChange}

                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="nominationItem"
                                label="NominationItem"
                                defaultValue={NominationValue.nominationItem}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="sequenceId"
                                label="SequenceId"
                                defaultValue={NominationValue.sequenceId}
                                onChange={handleTextFieldChange}

                            />
                        </Box>
                    </div>
                </div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-7'></div>
                <div className='col-md-5 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-primary" onClick={saveSampleByNominationValues} >Send Request</button>
                    </div>
                </div>
            </div>

            <div className='clearfix'></div>
            <div className='clearfix'></div>
        </DialogContent>

    );
}
export default SampleByNominationForm;